import { ISchoolYear } from 'apps/early-stage-office/src/app/core/models/school-year.interface';
import { IBaseAgency } from 'apps/early-stage-office/src/app/core/models/agency.interface';
import {
    IFranchiseCompanyData,
    IFranchiseSurveyData,
    IFranchiseYearlySettingsAgreement,
} from 'apps/early-stage-office/src/app/core/models/franchise-data.interface';
import { IGroupListItem } from 'apps/early-stage-office/src/app/core/models/group.interface';
import { IFranchiseTag } from 'apps/early-stage-office/src/app/core/models/franchise.interface';
import { TTableSchema, TTableSchemaDTO } from '../services/student-list-table-schemas.service';

export class SetFranchiseId {
    public static readonly type = '[FranchisePage] SetFranchiseId';

    constructor(public payload: { id: number }) {}
}

export class SetSelectedAgency {
    public static readonly type = '[FranchisePage] SetSelectedAgency';

    constructor(public payload: { agency: IBaseAgency }) {}
}

export class LoadSimplifiedAgenciesIfEmpty {
    public static readonly type = '[FranchisePage] LoadSimplifiedAgenciesIfEmpty';
}

export class ReloadSimplifiedAgencies {
    public static readonly type = '[FranchisePage] ReloadSimplifiedAgencies';
}

export class LoadSimplifiedLectorsIfEmpty {
    public static readonly type = '[FranchisePage] LoadSimplifiedLectorsIfEmpty';
}

export class ReloadSimplifiedLectors {
    public static readonly type = '[FranchisePage] ReloadSimplifiedLectors';
}

export class LoadFranchiseCounters {
    public static readonly type = '[FranchisePage] LoadFranchiseCounters';
}

export class LoadFranchise {
    public static readonly type = '[FranchisePage] LoadFranchise';
}

export class LoadFranchiseData {
    public static readonly type = '[FranchisePage] LoadFranchiseData';
}

export class EditFranchiseCompanyData {
    public static readonly type = '[FranchisePage] EditFranchiseCompanyData';

    constructor(public payload?: { data: IFranchiseCompanyData }) {}
}

export class EditFranchiseSurveyData {
    public static readonly type = '[FranchisePage] EditFranchiseSurveyData';

    constructor(public payload?: { data: IFranchiseSurveyData }) {}
}

export class EditFranchiseAgreementsSettings {
    public static readonly type = '[FranchisePage] EditFranchiseAgreementsData';

    constructor(public payload?: { data: IFranchiseYearlySettingsAgreement }) {}
}
export class LoadSimplifiedGroups {
    public static readonly type = '[FranchisePage] LoadSimplifiedGroups';

    constructor(public payload: { schoolYears: number[] }) {}
}

export class AddSimplifiedGroup {
    public static readonly type = '[FranchisePage] AddSimplifiedGroup';

    constructor(public payload?: { group: IGroupListItem }) {}
}

export class ResetFranchise {
    public static readonly type = '[FranchisePage] ResetFranchise';
}

export class EditFranchiseTags {
    public static readonly type = '[FranchisePage] EditFranchiseTags';

    constructor(public payload?: { tags: IFranchiseTag[] }) {}
}

export class LoadAgreementConsentsIfEmpty {
    public static readonly type = '[FranchisePage] LoadAgreementConsentsIfEmpty';

    constructor(public payload: { yearId: number }) {}
}

export class SetCurrentListTableSchema {
    public static readonly type = '[FranchisePage] SetCurrentListTableSchema';

    constructor(public payload: { schema: TTableSchema }) {}
}

export class LoadStudentListTableSchemas {
    public static readonly type = '[FranchisePage] LoadStudentListTableSchemas';
}

export class AddStudentListTableSchema {
    public static readonly type = '[FranchisePage] AddStudentListTableSchema';

    constructor(public payload: { schema: TTableSchemaDTO }) {}
}

export class RemoveStudentListTableSchema {
    public static readonly type = '[FranchisePage] RemoveStudentListTableSchema';

    constructor(public payload: { uid: string }) {}
}

export class SetFranchiseIsLoaded {
    public static readonly type = '[FranchisePage] SetFranchiseIsLoaded';
}
