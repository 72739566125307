import { Injectable } from '@angular/core';
import { ApiClientService } from './api.service';
import { IResults } from '../models/results.interface';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ILevelBase } from '../models/level.interface';

@Injectable({
    providedIn: 'root',
})
export class LevelsService {
    constructor(private api: ApiClientService) {}

    public getPath(): string {
        return 'levels';
    }

    public get(params: { [key: string]: unknown } = {}): Observable<ILevelBase[]> {
        return this.api.get<IResults<ILevelBase[]>>(this.getPath() + '/simple', { params }).pipe(map(dtos => dtos.results));
    }
}
