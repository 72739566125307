import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { IRelation } from '../../models/relation.interface';
import { IResults } from '../../models/results.interface';

@Injectable({
    providedIn: 'root',
})
export class RelationsService {
    private relationsSubject: ReplaySubject<IRelation[]>;

    constructor(private api: ApiClientService) {}

    public getRelations(): Observable<IRelation[]> {
        if (!this.relationsSubject) {
            return this.loadRelations();
        }

        return this.relationsSubject.asObservable();
    }

    public loadRelations(): Observable<IRelation[]> {
        this.relationsSubject = new ReplaySubject(1);

        return this.api.get<IResults<IRelation[]>>('dictionary/relations').pipe(
            map(r => r.results),
            tap(r => {
                this.relationsSubject.next(r);
            })
        );
    }
}
