import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SiteContext } from '../store/layout/layout.state';

export interface IPoll {
    icon: string;
    title: string;
    text: string;

    button: {
        url: string;
        title: string;
    };

    canClose?: boolean;
    siteContexts: SiteContext[];
}

@Injectable({
    providedIn: 'root',
})
export class PollsService {
    public static pollsMock: IPoll[] = [
        // {
        //     icon: 'lector-course',
        //
        //     title: 'Zapoznaj się z ofertą szkoleń na ten semestr!',
        //     text: 'Odwiedź naszą stronę i znajdź odpowiednie szkolenie dla siebie ',
        //
        //     button: {
        //         url: 'https://sites.google.com/earlystage.pl/szkoleniaearlystage/co-nowego?authuser=0',
        //         title: 'Przejdź do zapisów',
        //     },
        //     siteContexts: [SiteContext.Lector],
        // },
        // {
        //     icon: 'poll',
        //
        //     title: 'Hej, tu Dziewczyny z HR! Mamy do Ciebie kilka pytań',
        //     text: 'Anonimowa ankieta dla nowych lektorów ',
        //
        //     button: {
        //         url: 'https://docs.google.com/forms/d/e/1FAIpQLSf5tK-pUomouqsk11hfklWMgDP5FlcGmIbcI-8ppoL0Sh7sHg/viewform?usp=sf_link',
        //         title: 'Wypełnij ankietę',
        //     },
        //     siteContexts: [SiteContext.Lector],
        // },
        // {
        //     icon: 'poll',
        //
        //     title: 'Badanie wykorzystania narzędzi Google ',
        //     text: 'Na Wasze odpowiedzi czekamy do 6 czerwca',
        //
        //     button: {
        //         url: 'https://docs.google.com/forms/d/e/1FAIpQLSeQp9OLpNe_8pXtAwMxUSaEI3TY-RcLtgdxasU_9vMbc6D7RQ/viewform',
        //         title: 'Wypełnij ankietę',
        //     },
        //     siteContexts: [SiteContext.Lector],
        // },
        // {
        //     icon: 'poll',
        //
        //     title: 'Hej, tu HR team! Mamy do Ciebie kilka pytań',
        //     text: 'Anonimowa ankieta dla wszystkich lektorów ',
        //
        //     button: {
        //         url: 'https://docs.google.com/forms/d/e/1FAIpQLScnreUoEaJT6gxJZq9Tprf4oKsphoZjFbsAhocPi-ny_DoU1w/viewform',
        //         title: 'Wypełnij ankietę',
        //     },
        //     siteContexts: [SiteContext.Lector],
        // },
    ];

    constructor() {}

    public get(): Observable<IPoll[]> {
        return of(PollsService.pollsMock);
    }
}
