import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiClientService } from '../api.service';
import { map, tap } from 'rxjs/operators';
import { ICounty, IDistrict, ILocality, IProvince } from '../../models/province.interface';
import { IResults } from '../../models/results.interface';

@Injectable({
    providedIn: 'root',
})
export class ProvincesService {
    private provincesSubject: ReplaySubject<IProvince[]>;

    constructor(private api: ApiClientService) {}

    public getProvinces(): Observable<IProvince[]> {
        if (!this.provincesSubject) {
            return this.loadProvinces();
        }

        return this.provincesSubject.asObservable();
    }

    public loadProvinces(): Observable<IProvince[]> {
        this.provincesSubject = new ReplaySubject(1);

        return this.api.get<IResults<IProvince[]>>('dictionary/teryt/provinces').pipe(
            map(r => r.results),
            tap(r => {
                this.provincesSubject.next(r);
            })
        );
    }

    public loadCounties(provinceId: number): Observable<ICounty[]> {
        return this.api.get<IResults<ICounty[]>>('dictionary/teryt/counties/' + provinceId).pipe(map(r => r.results));
    }

    public loadLocalities(countyId: number): Observable<ILocality[]> {
        return this.api.get<IResults<ILocality[]>>('dictionary/teryt/localities/' + countyId).pipe(map(r => r.results));
    }

    public loadDistricts(localityId: number): Observable<IDistrict[]> {
        return this.api.get<IResults<IDistrict[]>>('dictionary/teryt/districts/' + localityId).pipe(map(r => r.results));
    }
}
