import { Injectable, ComponentRef, ApplicationRef, Injector, EmbeddedViewRef, Type } from '@angular/core';
import { ModalConfig } from './modal-config';
import { ModalComponent } from '../components/modal/modal.component';
import { ModalRef } from './modal-ref';
import { ModalInjector } from './modal.injector';
import { take } from 'rxjs/operators';
import { Overlay, OverlayConfig, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

/**
 * @deprecated use EscModalService instead
 */
@Injectable({ providedIn: 'root' })
export class ModalsService {
    private modalComponentRef: ComponentRef<ModalComponent>;

    private readonly _scrollStrategy: ScrollStrategy;
    private readonly _positionStrategy: PositionStrategy;

    constructor(
        private appRef: ApplicationRef,
        private injector: Injector,

        private _overlay: Overlay
    ) {
        this._scrollStrategy = this._overlay.scrollStrategies.block();
        this._positionStrategy = this._overlay.position().global().centerHorizontally().centerVertically();
    }

    public open<T, D>(componentType: Type<T>, config: ModalConfig<D> = {}): ModalRef {
        /*if (!config.data) {
            config.data = {};
        }*/

        if (this.modalComponentRef) {
            this.removeModalComponentFromBody(this.modalComponentRef);
        }
        const ref = this.appendModalComponentToBody(config);

        this.modalComponentRef.instance.childComponentType = componentType;
        return ref;
    }

    private appendModalComponentToBody(config: ModalConfig): ModalRef {
        const overlayConfig = this._getOverlayConfig();
        const overlayRef = this._overlay.create(overlayConfig);

        const modalRef = new ModalRef(config);
        const injector = this.getInjector(config, modalRef);

        const containerPortal = new ComponentPortal(ModalComponent, null, injector);
        const containerRef = overlayRef.attach(containerPortal);

        this.modalComponentRef = containerRef;

        return modalRef;
    }

    private _getOverlayConfig(): OverlayConfig {
        return {
            positionStrategy: this._positionStrategy,
            scrollStrategy: this._scrollStrategy,
            hasBackdrop: true,
            backdropClass: ['overlay-backdrop'],
            panelClass: 'overlay-panel',
            disposeOnNavigation: true,
        };
    }

    public removeModalComponentFromBody(modalComponentRef?: ComponentRef<ModalComponent>): void {
        if (!modalComponentRef) {
            modalComponentRef = this.modalComponentRef;
        }

        if (!modalComponentRef) {
            return;
        }

        setTimeout(() => {
            modalComponentRef.instance.wasClosed = true;
            modalComponentRef.instance.cd.detectChanges();
        });

        setTimeout(() => {
            this.appRef.detachView(modalComponentRef.hostView);
            modalComponentRef.destroy();
            if (modalComponentRef === this.modalComponentRef) {
                this.modalComponentRef = null;
            }
        }, 200);
    }

    private getInjector(config: ModalConfig, modalRef: ModalRef): ModalInjector {
        const map = new WeakMap();
        map.set(ModalConfig, config);
        map.set(ModalRef, modalRef);

        const sub = modalRef.afterClosing$.pipe(take(1)).subscribe(() => {
            this.removeModalComponentFromBody();
            sub.unsubscribe();
        });

        return new ModalInjector(this.injector, map);
    }
}
