import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalsService } from 'apps/early-stage-office/src/app/utility-modules/modals/services/modals.service';
import { SupportModalComponent } from '../../modals/support-modal/support-modal.component';

@Component({
    selector: 'es-support-navigation-item',
    templateUrl: './support-navigation-item.component.html',
    styleUrls: ['./support-navigation-item.component.scss'],
    standalone: true,
})
export class SupportNavigationItemComponent {
    @Input() public modifiers: Array<'white'> = [];
    public get classModifiers(): string {
        return this.modifiers?.map(a => 'is-' + a).join(' ') || '';
    }
    constructor(
        private modals: ModalsService,
        private router: Router
    ) {}

    public openSupportModal(): void {
        setTimeout(() => {
            this.router.navigate([{ outlets: { menu: null } }]);

            this.modals.open(SupportModalComponent, {
                style: 'anchored',
                anchorType: 'right',
                width: 500,
            });
        });
    }
}
