import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiClientService } from '../api.service';
import { map, tap } from 'rxjs/operators';
import { IResults } from '../../models/results.interface';
import { ISchoolYear, ISchoolYearAddDTO } from '../../models/school-year.interface';

@Injectable({
    providedIn: 'root',
})
export class SchoolYearsService {
    private schoolYearsSubject: ReplaySubject<ISchoolYear[]>;

    constructor(private api: ApiClientService) {}

    public getSchoolYears(): Observable<ISchoolYear[]> {
        if (!this.schoolYearsSubject) {
            return this.loadSchoolYears();
        }

        return this.schoolYearsSubject.asObservable();
    }

    public loadSchoolYears(): Observable<ISchoolYear[]> {
        this.schoolYearsSubject = new ReplaySubject(1);

        return this.api.get<IResults<ISchoolYear[]>>('dictionary/settings/schoolYears').pipe(
            map(r => r.results.map(y => y)),
            tap(r => {
                this.schoolYearsSubject.next(r);
            })
        );
    }

    public editSchoolYear(data: Partial<ISchoolYearAddDTO>): Observable<ISchoolYear[]> {
        return this.api.patch<IResults<ISchoolYear[]>>('settings/schoolYears/' + data.id, data).pipe(
            map(r => r.results.map(y => y)),
            tap(r => {
                this.schoolYearsSubject.next(r);
            })
        );
    }
}
