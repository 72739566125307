import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { ISchoolType } from '../../models/school-type.interface';
import { IResults } from '../../models/results.interface';

@Injectable({
    providedIn: 'root',
})
export class SchoolTypesService {
    private schoolTypesSubject: ReplaySubject<ISchoolType[]>;

    constructor(private api: ApiClientService) {}

    public getSchoolTypes(): Observable<ISchoolType[]> {
        if (!this.schoolTypesSubject) {
            return this.loadSchoolTypes();
        }

        return this.schoolTypesSubject.asObservable();
    }

    public loadSchoolTypes(): Observable<ISchoolType[]> {
        this.schoolTypesSubject = new ReplaySubject(1);

        return this.api.get<IResults<ISchoolType[]>>('dictionary/schoolTypes').pipe(
            map(r => r.results),
            tap(r => {
                this.schoolTypesSubject.next(r);
            })
        );
    }
}
