<div
    class="nav-item {{ classModifiers }}"
    [ngClass]="{ 'is-active': isActive, 'has-content': item.content, 'has-children': children && children.length > 0, 'is-submenu-active': submenuActive }"
    #link>
    <ng-container *ngIf="!item.disabled; else loggedOut">
        <button type="button" class="nav-item__link esc-button is-shadowed" (click)="toggleContent()" *ngIf="item.content">
            <span class="nav-item__title">{{ item.title }}</span>
            <es-icon icon="chevron-down" [width]="16" [height]="16"></es-icon>
        </button>
        <a [routerLink]="['', { outlets: { menu: null, primary: '' + item.url } }]" class="nav-item__link esc-button is-shadowed" *ngIf="!item.content && !item.children">
            {{ item.title }}
        </a>

        <button type="button" class="nav-item__link esc-button is-shadowed" (click)="toggleSubmenu()" *ngIf="item.children">
            <span class="nav-item__title">{{ item.title }}</span>
            <es-icon icon="chevron-down" [width]="16" [height]="16"></es-icon>
        </button>

        <ul class="nav-item__children" *ngIf="item.children" [ngClass]="{ 'is-active': submenuActive }">
            <li *ngFor="let child of children">
                <a [routerLink]="['', { outlets: { menu: null, primary: '' + child.url } }]" class="nav-item__child esc-button is-shadowed" (click)="toggleSubmenu(false)" *ngIf="!child.disabled">
                    {{ child.title }}
                </a>
                <button type="button" class="nav-item__child esc-button is-shadowed is-disabled" routerLinkActive="active" *ngIf="child.disabled">
                    {{ child.title }}
                </button>
            </li>
        </ul>
    </ng-container>

    <ng-template #loggedOut>
        <button type="button" class="nav-item__link esc-button is-shadowed is-disabled" routerLinkActive="active">
            {{ item.title }}
        </button>
    </ng-template>

    <div class="nav-item__content" [ngClass]="{ 'is-visible': showContent }">
        <div class="container">
            <div #container></div>
        </div>
    </div>
</div>
