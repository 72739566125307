<div class="support-modal">
    <div class="support-modal__header">
        <h2>Szukasz pomocy?</h2>
    </div>

    <div class="support-modal__links" *ngIf="instructions && instructions.length > 0">
        <p class="support-modal__links-text">
            Jeżeli masz pytanie związane z aktualnym funkcjonowaniem ES Office - w pierwszej kolejności poszukaj odpowiedzi w jednej z dostępnych instrukcji:
        </p>

        <a target="_blank" [href]="item.url" class="support-instruction link link--text" *ngFor="let item of instructions">
            <img
                class="support-instruction__image"
                [src]="'/assets/images/support/' + item.imageName + '.jpg'"
                [srcset]="'/assets/images/support/' + item.imageName + '@2x.jpg 2x, /assets/images/support/' + item.imageName + '@3x.jpg 3x'" />
            <span class="support-instruction__content">
                {{ item.preTitle }}
                <br />
                {{ item.title }}
            </span>
        </a>
    </div>

    <div class="support-modal__footer">
        <a href="https://links.earlystage.pl/help" target="_blank" class="support-link is-grey">
            <div class="support-link__image-container">
                <span class="support-link__image">
                    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="64" cy="64" r="64" fill="#FFF7E7" />
                        <path
                            d="M108.923 60.948C108.923 65.8919 107.839 69.896 106.503 69.896C105.166 69.896 104.083 65.8919 104.083 60.948C104.083 56.0092 105.166 52 106.503 52C107.839 52 108.923 56.0041 108.923 60.948Z"
                            fill="#161616" />
                        <path
                            d="M109 74.1659C109 75.5347 107.88 76.6378 106.497 76.6378C105.115 76.6378 104 75.5296 104 74.1659C104 72.7971 105.12 71.694 106.497 71.694C107.88 71.694 109 72.8023 109 74.1659Z"
                            fill="#EE4023" />
                        <path d="M37.172 66.344H26.768V77H22.628V51.8H26.768V62.636H37.172V51.8H41.312V77H37.172V66.344Z" fill="#EE4023" />
                        <path d="M50.7094 55.508V62.636H61.1134V66.344H50.7094V73.292H61.1134V77H46.5694V51.8H61.1134V55.508H50.7094Z" fill="#EC268F" />
                        <path d="M78.1026 73.292V77H66.1866V51.8H70.3266V73.292H78.1026Z" fill="#161616" />
                        <path
                            d="M81.6202 51.8H90.6562C92.0002 51.8 93.2122 52.04 94.2922 52.52C95.3962 52.976 96.3322 53.612 97.1002 54.428C97.8922 55.244 98.4922 56.192 98.9002 57.272C99.3322 58.328 99.5482 59.444 99.5482 60.62C99.5482 61.82 99.3322 62.948 98.9002 64.004C98.4922 65.06 97.8922 65.996 97.1002 66.812C96.3322 67.628 95.3962 68.276 94.2922 68.756C93.2122 69.212 92.0002 69.44 90.6562 69.44H85.7602V77H81.6202V51.8ZM90.6562 65.732C91.3762 65.732 92.0242 65.6 92.6002 65.336C93.2002 65.048 93.7042 64.676 94.1122 64.22C94.5202 63.74 94.8322 63.2 95.0482 62.6C95.2882 61.976 95.4082 61.316 95.4082 60.62C95.4082 59.924 95.2882 59.276 95.0482 58.676C94.8322 58.052 94.5202 57.512 94.1122 57.056C93.7042 56.576 93.2002 56.204 92.6002 55.94C92.0242 55.652 91.3762 55.508 90.6562 55.508H85.7602V65.732H90.6562Z"
                            fill="#00A7E9" />
                    </svg>
                </span>
            </div>
            <div class="support-link__content">
                <p>Chcesz zgłosić błąd lub sugestię?</p>
                <span class="support-link__text">Przejdź do portalu pomocy Early Stage</span>
            </div>
        </a>
        <button class="support-link is-settings" (click)="openSettingsModal()">
            <div class="support-link__image-container">
                <span class="support-link__image">
                    <img
                        [src]="'/assets/images/support/link-settings.png'"
                        [srcset]="'/assets/images/support/link-settings@2x.png 2x, /assets/images/support/link-settings@3x.png 3x'" />
                </span>
            </div>
            <div class="support-link__content">
                <span class="support-link__text">Ustawienia</span>
            </div>
        </button>
    </div>
</div>
