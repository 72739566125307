import { Injectable } from '@angular/core';
import { IPermissions, IPermissionsPart, IUserPermissions, IUserPermissionsPart } from '../models/permissions.interface';
import { IUser, IUserRole } from '../models/user.interface';
import { TRoleName } from '../models/role.interface';
import { IProfileRole } from '../models/profile.interface';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    // TODO: pobierać to z api
    private _permissions: IPermissions = {
        admin: {
            get: ['ROLE_SUPER_ADMIN', 'ROLE_CENTRAL_ADMINISTRATION'],
        },
        franchise: {
            get: ['ROLE_SUPER_ADMIN', 'ROLE_FRANCHISEE', 'ROLE_LEADER', 'ROLE_SECRETARIAT', 'ROLE_DOS'],
            pages: {
                employees: {
                    edit: ['ROLE_SUPER_ADMIN', 'ROLE_FRANCHISEE', 'ROLE_LEADER'],
                    delete: ['ROLE_SUPER_ADMIN', 'ROLE_FRANCHISEE', 'ROLE_LEADER'],
                },
                agencies: {
                    edit: ['ROLE_SUPER_ADMIN', 'ROLE_FRANCHISEE', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
                },
                groups: {
                    edit: ['ROLE_SUPER_ADMIN', 'ROLE_FRANCHISEE', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
                    delete: ['ROLE_SUPER_ADMIN', 'ROLE_FRANCHISEE', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
                },
                students: {
                    edit: ['ROLE_SUPER_ADMIN', 'ROLE_FRANCHISEE', 'ROLE_LEADER', 'ROLE_SECRETARIAT', 'ROLE_DOS'],
                    delete: ['ROLE_SUPER_ADMIN', 'ROLE_FRANCHISEE', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
                },
            },
        },
    };

    public get permissions(): IPermissions {
        return this._permissions;
    }

    public getUserPermissions(user: IUser): IUserPermissions {
        const userPermissions: Partial<IUserPermissions> = this.parsePermission(user.roles, this.permissions);

        return userPermissions as IUserPermissions;
    }

    public getUserPermissionsByRole(role: IProfileRole): IUserPermissions {
        const userPermissions: Partial<IUserPermissions> = this.parsePermission([role], this.permissions);

        return userPermissions as IUserPermissions;
    }

    private hasPermission(userRoles: IUserRole[], roles: TRoleName[]): boolean {
        return userRoles.some(r => roles.includes(r.role));
    }

    private parsePermission(roles: IUserRole[], part: IPermissionsPart): IUserPermissionsPart {
        const userPart = {};
        for (const key in part) {
            if (part.hasOwnProperty(key)) {
                const element = part[key];

                if (Array.isArray(element)) {
                    userPart[key] = this.hasPermission(roles, element);
                } else {
                    userPart[key] = this.parsePermission(roles, element);
                }
            }
        }

        return userPart as IUserPermissionsPart;
    }
}
