import {
    Component,
    OnInit,
    OnDestroy,
    Type,
    ViewChild,
    ComponentFactoryResolver,
    ComponentRef,
    ChangeDetectorRef,
    AfterViewInit,
    ElementRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { fadeAnimation, fromBottomAnimation, fromLeftAnimation, fromRightAnimation } from 'apps/early-stage-office/src/app/core/animations';
import { ModalTemplateDirective } from '../../directives/modal-template.directive';
import { ModalConfig } from '../../services/modal-config';
import { ModalRef } from '../../services/modal-ref';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { Router, NavigationEnd } from '@angular/router';
import { AddCurrentScrollableElement, RemoveCurrentScrollableElement } from 'apps/early-stage-office/src/app/core/store/layout/layout.actions';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgClass, NgTemplateOutlet, NgStyle } from '@angular/common';

@Component({
    selector: 'es-modal',
    exportAs: 'modal',
    templateUrl: './modal.component.html',
    animations: [fadeAnimation, fromBottomAnimation, fromRightAnimation, fromLeftAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgClass, NgTemplateOutlet, NgStyle, InlineSVGModule, ModalTemplateDirective],
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private componentRef: ComponentRef<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public childComponentType: Type<any>;

    @ViewChild(ModalTemplateDirective, { static: false }) public modalTemplate: ModalTemplateDirective;
    @ViewChild('modalContainer', { static: false }) public modalContainer: ElementRef;
    @ViewChild('modalWrapper', { static: false }) public modalWrapper: ElementRef;
    @ViewChild('modal', { static: false }) public modalElementRef: ElementRef;

    public style: 'normal' | 'online' | 'anchored';
    public anchorType?: 'left' | 'right' | 'bottom';
    public preventRouterClose: boolean = false;
    public modalCloseButton: boolean = true;

    public width: number;

    public wasClosed: boolean = false;

    public destroy$: Subject<boolean> = new Subject<boolean>();

    public isMobile: boolean;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        public store: Store,
        public cd: ChangeDetectorRef,
        private config: ModalConfig,
        public modalRef: ModalRef,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.style = this.config.style || 'normal';
        this.anchorType = this.config.anchorType || 'right';
        this.preventRouterClose = this.config.preventRouterClose || false;
        this.modalCloseButton = this.config.modalCloseButton !== false;
        this.isMobile = this.store.selectSnapshot(LayoutState.isMobile);

        if (this.config.width) {
            this.width = this.config.width;
        }

        this.router.events
            .pipe(takeUntil(this.destroy$))
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((val: NavigationEnd) => {
                if (!this.preventRouterClose) {
                    this.modalRef.close();
                }
            });

        this.modalRef.config$.pipe(takeUntil(this.destroy$)).subscribe(config => {
            setTimeout(() => {
                this.config = config;

                if (this.config.width) {
                    this.width = this.config.width;
                }

                this.cd.detectChanges();
            });
        });

        this.modalRef.afterClosing$.pipe(takeUntil(this.destroy$)).subscribe(close => {
            this.store.dispatch(new RemoveCurrentScrollableElement({ element: this.modalWrapper.nativeElement }));
        });
    }

    public ngAfterViewInit() {
        this.loadChildComponent(this.childComponentType);
        this.store.dispatch(new AddCurrentScrollableElement({ element: this.modalWrapper.nativeElement }));
        this.cd.detectChanges();

        // if (this.style === 'anchored') {
        //     setTimeout(() => {
        //         this.defineOnClickEvent();
        //     }, 200);
        // }
    }

    public ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }

        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public closeClick(): void {
        this.modalRef.close();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public loadChildComponent(componentType: Type<any>) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

        const viewContainerRef = this.modalTemplate.viewContainerRef;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent(componentFactory);
    }

    public closeOutside(event: MouseEvent): void {
        if (!this.modalElementRef) {
            return;
        }

        const canClose: boolean | number = event.composedPath().indexOf(this.modalElementRef.nativeElement);

        if (canClose === -1) {
            this.modalRef.close();
        }
    }

    public defineOnClickEvent(): void {
        fromEvent(document, 'click')
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: MouseEvent) => {
                this.closeOutside(event);
            });
    }
}
