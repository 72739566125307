import { Injectable } from '@angular/core';
import { ApiClientService } from '../api.service';
import { Observable, of } from 'rxjs';
import { version } from '../../../../version';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    constructor(private api: ApiClientService) {}

    public getApiVersion(): Observable<string> {
        return this.api.get<{ version: string }>('dictionary/version').pipe(map(v => v.version));
    }

    public getAppVersion(): Observable<string> {
        return of(version);
    }
}
