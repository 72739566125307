import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { IAgreementConsent } from '../../models/agreement.interface';
import { IResults } from '../../models/results.interface';

@Injectable({
    providedIn: 'root',
})
export class AgreementConsentsService {
    constructor(private api: ApiClientService) {}

    public get(yearId: number): Observable<IAgreementConsent[]> {
        return this.api.get<IResults<IAgreementConsent[]>>('dictionary/consents', { params: { 'filter[schoolYear]': yearId } }).pipe(map(r => r.results));
    }
}
