import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ProfileService } from '../../authentication/profile.service';
import { IProfile, IProfileRole } from '../../models/profile.interface';
import { ChangePhone, GetPermissions, GetProfile, ResetProfileState, SetCurrentRole, SetCurrentRoleByUrl, SetProfile } from './profile.actions';
import { IUserPermissions } from '../../models/permissions.interface';
import { PermissionsService } from '../../services/permisions.service';
import { Injectable } from '@angular/core';
import { TRoleName } from '../../models/role.interface';
import { IUserRole } from '../../models/user.interface';

export interface ProfileStateModel {
    profile: IProfile;
    permissions: IUserPermissions;
    currentRole: IProfileRole;
}

@State<ProfileStateModel>({
    name: 'profile',
    defaults: {
        profile: null,
        currentRole: null,
        permissions: null,
    },
})
@Injectable()
export class ProfileState {
    constructor(
        private profileService: ProfileService,
        private permissions: PermissionsService
    ) {}

    @Selector()
    public static getState(state: ProfileStateModel) {
        return state;
    }

    @Selector()
    public static profile(state: ProfileStateModel) {
        return state.profile;
    }

    @Selector()
    public static user(state: ProfileStateModel) {
        return state.profile.user;
    }

    public static hasRole(role: TRoleName) {
        return createSelector([this], (state: ProfileStateModel) => {
            return state.profile?.user.roles.some(r => r.role === role);
        });
    }

    public static hasRoleWithFranchise(role: TRoleName, franchiseId: number) {
        return createSelector([this], (state: ProfileStateModel) => {
            if (state.currentRole?.role === 'ROLE_SUPER_ADMIN') {
                return true;
            }

            return state.profile?.user.roles.some(r => r.role === role && r.roleFranchises?.some(rf => rf.franchise?.id === franchiseId));
        });
    }

    public static someRole(roles: TRoleName[]) {
        return createSelector([this], (state: ProfileStateModel) => {
            return state.profile?.user.roles.some(r => roles.some(rName => r.role === rName));
        });
    }

    public static someCurrentRole(roles: TRoleName[]) {
        return createSelector([this], (state: ProfileStateModel) => {
            return roles.some(rName => state.currentRole?.role === rName);
        });
    }

    public static someActiveRole(roles: TRoleName[]) {
        return createSelector([this], (state: ProfileStateModel) => {
            const user = state.profile?.user;

            if (!user || !user.roles || user.roles.length === 0) {
                return false;
            }

            return user.roles.some(r => roles.some(rName => r.role === rName) && ProfileService.isRoleActive(r));
        });
    }

    public static isRoleCurrent(role: IProfileRole) {
        return createSelector([this], (state: ProfileStateModel) => {
            const profile = state.profile;

            if (!profile || !profile.roles || profile.roles.length === 0) {
                return false;
            }

            return state.currentRole?.role === role?.role && state.currentRole?.franchise?.franchise?.id === role.franchise?.franchise?.id;
        });
    }

    public static hasCurrentRoleAccessToAgency(agencyId: number) {
        return createSelector([this], (state: ProfileStateModel) => {
            const currentRole = state.currentRole;

            return (
                currentRole.roleFranchises?.some(rf => rf.agencies.some(a => a.id === agencyId) || rf.accessToAllAgencies) ||
                currentRole.role === 'ROLE_SUPER_ADMIN'
            );
        });
    }

    @Selector()
    public static hasMultipleFranchises(state: ProfileStateModel): boolean {
        return state.profile?.hasMultipleFranchises;
    }

    @Selector()
    public static dosAgencies(state: ProfileStateModel) {
        const dos = state.profile.user.roles.find(r => r.role === 'ROLE_DOS');

        if (!dos) {
            return null;
        }

        let agencies = dos.roleFranchises
            ?.map(rf => rf.agencies)
            .reduce((a, b) => {
                a.push(...b);
                return a;
            }, []);

        agencies = agencies.filter((a, index) => index === agencies.findIndex(u => u.id === a.id));

        return agencies;
    }

    @Selector()
    public static permissions(state: ProfileStateModel) {
        return state.permissions;
    }

    @Selector()
    public static franchisePagesPermissions(state: ProfileStateModel) {
        return state.permissions.franchise.pages;
    }

    @Selector()
    public static roles(state: ProfileStateModel): IUserRole[] {
        return state.profile?.roles;
    }

    @Selector()
    public static profileRoles(state: ProfileStateModel) {
        return state.profile?.profileRoles;
    }

    @Selector()
    public static adminProfileRoles(state: ProfileStateModel): IProfileRole[] {
        return state.profile?.profileRoles.filter(p => p.role === 'ROLE_ADMIN' || p.role === 'ROLE_SUPER_ADMIN' || p.role === 'ROLE_CENTRAL_ADMINISTRATION');
    }

    @Selector()
    public static isCurrentRoleAdmin(state: ProfileStateModel): boolean {
        const p = state.currentRole;
        return p.role === 'ROLE_ADMIN' || p.role === 'ROLE_SUPER_ADMIN' || p.role === 'ROLE_CENTRAL_ADMINISTRATION';
    }

    @Selector()
    public static isUserActive(state: ProfileStateModel) {
        const user = state.profile?.user;

        if (!user || !user.roles || user.roles.length === 0) {
            return false;
        }

        for (const role of user.roles) {
            if (ProfileService.isRoleActive(role)) {
                return true;
            }
        }

        return false;
    }

    @Selector()
    public static isUserAdmin(state: ProfileStateModel) {
        const user = state.profile?.user;

        if (!user || !user.roles || user.roles.length === 0) {
            return false;
        }

        return ProfileState.someActiveRole(['ROLE_ADMIN', 'ROLE_CENTRAL_ADMINISTRATION', 'ROLE_SUPER_ADMIN'])(state);
    }

    @Selector()
    public static isUserInactive(state: ProfileStateModel) {
        return !ProfileState.isUserActive(state);
    }

    @Selector()
    public static groups(state: ProfileStateModel) {
        return state.profile.user.groups;
    }

    @Selector()
    public static currentRole(state: ProfileStateModel) {
        return state.currentRole;
    }

    @Action(GetProfile)
    public getProfile({ dispatch }: StateContext<ProfileStateModel>) {
        return this.profileService.getProfile().pipe(
            tap(profile => {
                dispatch(new SetProfile({ profile: profile }));
            })
        );
    }

    @Action(ChangePhone)
    public changePhone({ dispatch }: StateContext<ProfileStateModel>, { payload }: ChangePhone) {
        return this.profileService.changePhone(payload.data).pipe(
            tap(() => {
                return dispatch(new GetProfile());
            })
        );
    }

    @Action(SetProfile)
    public setProfile({ getState, patchState, dispatch }: StateContext<ProfileStateModel>, { payload }: SetProfile) {
        const currentRole = getState().currentRole;

        if (payload.profile) {
            patchState({
                currentRole: this.getCurrentRoleByUrl(payload.profile, currentRole) || payload.profile.profileRoles[0],
            });
        }

        patchState({
            profile: payload.profile,
        });

        dispatch(new GetPermissions());
    }

    @Action(SetCurrentRole)
    public setCurrentRole({ patchState, dispatch }: StateContext<ProfileStateModel>, { payload }: SetCurrentRole) {
        patchState({
            currentRole: payload.role,
        });

        dispatch(new GetPermissions());
    }

    @Action(SetCurrentRoleByUrl)
    public setCurrentRoleByUrl({ dispatch, getState }: StateContext<ProfileStateModel>) {
        const currentRole = getState().currentRole;
        const profile = getState().profile;

        if (!currentRole) {
            return;
        }

        setTimeout(() => {
            if (!profile) {
                return;
            }

            const isCurrentRoleValid = currentRole.route ? window.location.pathname.indexOf(currentRole.route[0]) === 0 : false;

            if (isCurrentRoleValid) {
                return;
            }

            const role = profile.profileRoles.find(r => {
                return r.route && window.location.pathname.indexOf(r.route[0]) === 0;
            });

            if (role && role !== currentRole) {
                dispatch(new SetCurrentRole({ role }));
            }
        });
    }

    @Action(GetPermissions)
    public getPermissions({ patchState, getState }: StateContext<ProfileStateModel>) {
        // const profile = getState().profile;
        const currentRole = getState().currentRole;

        if (!currentRole) {
            return;
        }

        patchState({
            permissions: this.permissions.getUserPermissionsByRole(currentRole),
        });
    }

    @Action(ResetProfileState)
    public resetProfileState({ patchState }: StateContext<ProfileStateModel>) {
        patchState({
            permissions: null,
            profile: null,
            currentRole: null,
        });
    }

    private getCurrentRoleByUrl(profile: IProfile, baseCurrentRole: IProfileRole): IProfileRole {
        if (!profile) {
            return null;
        }

        const currentRole = baseCurrentRole
            ? profile.profileRoles.find(r => baseCurrentRole.role === r.role && r.franchise?.franchise.id === baseCurrentRole.franchise?.franchise.id)
            : null;

        if (currentRole?.role === 'ROLE_ADMIN' || currentRole?.role === 'ROLE_SUPER_ADMIN' || currentRole?.role === 'ROLE_CENTRAL_ADMINISTRATION') {
            return currentRole;
        }

        if (currentRole && window.location.pathname === '/') {
            return currentRole;
        }

        const isCurrentRoleValid = currentRole && currentRole.route ? window.location.pathname.indexOf(currentRole.route[0]) === 0 : false;

        if (isCurrentRoleValid) {
            return currentRole;
        }

        return profile.profileRoles.find(r => {
            return r.route && window.location.pathname.indexOf(r.route[0]) === 0;
        });
    }
}
