import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { INavigationItem } from 'apps/early-stage-office/src/app/core/models/navigation.interface';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { WithDestroyComponent } from '../../../../core/abstract/abstract-with-destroy-component';
import { IconComponent } from '../../../../utility-modules/icon/components/icon/icon.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'es-navigation-item',
    templateUrl: './navigation-item.component.html',
    styleUrls: ['./navigation-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgIf, IconComponent, RouterLink, NgFor, RouterLinkActive],
})
export class NavigationItemComponent extends WithDestroyComponent implements AfterContentInit {
    public showContent: boolean = false;
    public submenuActive: boolean = false;

    private clickOutsideEvent;

    @Input() public item: INavigationItem;
    @Input() public modifiers: Array<'white'> = [];

    @ViewChild('link', { static: true }) public link: ElementRef;
    @ViewChild('container', { static: true, read: ViewContainerRef }) public container: ViewContainerRef;

    public isActive: boolean = false;
    public get classModifiers(): string {
        return this.modifiers?.map(a => 'is-' + a).join(' ') || '';
    }

    public get children(): INavigationItem[] {
        return this.item.children;
    }

    constructor(
        private cd: ChangeDetectorRef,
        private _router: Router
    ) {
        super();
    }

    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.createDynamicComponent();
        });

        this.clickOutsideEvent = e => {
            this.clickOutside(e);
        };

        if (this.item.children) {
            this.isActive = this.item.children.some(c => this._router.isActive(c.url, false));
        } else if (this.item.url) {
            this.isActive = this._router.isActive(this.item.url, false);
        }

        this._router.events
            .pipe(
                takeUntil(this.destroy$),
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                if (this.item.children) {
                    this.isActive = this.item.children.some(c => this._router.isActive(c.url, false));
                } else if (this.item.url) {
                    this.isActive = this._router.isActive(this.item.url, false);
                }

                this.cd.detectChanges();
            });
    }

    public createDynamicComponent(): void {
        if (!this.item.content) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const componentRef = this.container.createComponent(this.item.content);
        // const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        // this.container.nativeElement.appendChild(domElem);

        componentRef.changeDetectorRef.detectChanges();
    }

    public toggleContent(value?: boolean): void {
        this.showContent = value !== undefined ? value : !this.showContent;
        if (this.submenuActive || this.showContent) {
            document.addEventListener('click', this.clickOutsideEvent);
        } else {
            document.removeEventListener('click', this.clickOutsideEvent);
        }

        this.cd.detectChanges();
    }

    public clickOutside(event: MouseEvent): void {
        const composedPath = event.composedPath(),
            isClickedToggle: boolean | number = composedPath.indexOf(this.link.nativeElement) !== -1,
            isClickedContent: boolean | number = composedPath.indexOf(this.container.element.nativeElement) !== -1;

        if (!isClickedToggle && !isClickedContent) {
            this.toggleContent(false);
            this.toggleSubmenu(false);
        }
    }

    public toggleSubmenu(value?: boolean): void {
        this.submenuActive = value !== undefined ? value : !this.submenuActive;

        if (this.submenuActive || this.showContent) {
            document.addEventListener('click', this.clickOutsideEvent);
        } else {
            document.removeEventListener('click', this.clickOutsideEvent);
        }

        this.cd.detectChanges();
    }
}
