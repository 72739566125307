import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { IResults } from '../../models/results.interface';
import { ISchoolSettings, ISchoolSettingsDTO } from '../../models/school-settings.interface';

@Injectable({
    providedIn: 'root',
})
export class SchoolSettingsService {
    // TODO: Usunąć schoolSettings
    private schoolSettingsSubject: ReplaySubject<ISchoolSettings>;

    constructor(private api: ApiClientService) {}

    public getSchoolSettings(): Observable<ISchoolSettings> {
        if (!this.schoolSettingsSubject) {
            return this.loadSchoolSettings();
        }

        return this.schoolSettingsSubject.asObservable();
    }

    public loadSchoolSettings(): Observable<ISchoolSettings> {
        this.schoolSettingsSubject = new ReplaySubject(1);

        return this.api.get<IResults<ISchoolSettingsDTO>>('dictionary/settings/school').pipe(
            map(r => this.parse(r.results)),
            tap(r => {
                this.schoolSettingsSubject.next(r);
            })
        );
    }

    public parse(dto: ISchoolSettingsDTO): ISchoolSettings {
        return {
            ...dto,
            currentSemesterYear: parseInt(dto.currentSemesterYear, 0),
            lessonsNumberPerYear: parseInt(dto.lessonsNumberPerYear, 0),
        };
    }
}
