import { Injectable } from '@angular/core';
export class IModalTemplate {
    public title: string;
    public label: string;
    public buttonText: string;
    public type?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
@Injectable()
export class ModalConfig<D = any> {
    public template?: IModalTemplate;
    public data?: D;
    public width?: number;
    public style?: 'normal' | 'online' | 'anchored';
    public anchorType?: 'left' | 'right' | 'bottom';
    public preventRouterClose?: boolean;
    public modalCloseButton?: boolean;
}
