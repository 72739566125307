import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngxs/store';
import { TRoleName } from 'apps/early-stage-office/src/app/core/models/role.interface';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';
import { ModalsService } from '../../../utility-modules/modals/services/modals.service';
import { SettingsModalComponent } from '../settings-modal/settings-modal.component';
import { NgIf, NgFor } from '@angular/common';

type TInstruction = {
    imageName: string;
    preTitle: string;
    title: string;
    url: string;
    roles: TRoleName[];
};

@Component({
    selector: 'es-support-modal',
    templateUrl: './support-modal.component.html',
    styleUrls: ['./support-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor],
})
export class SupportModalComponent implements OnInit {
    private allInstructions: TInstruction[] = [
        {
            imageName: 'instruction-franchisee',
            preTitle: 'Early Stage OFFICE',
            title: 'Instrukcja dla FRANCZYZOBIORCY',
            url: 'https://docs.google.com/presentation/d/1tSTrF1fnK5pMD0L7LOTFtPm6PfHckrUsjHtrqj1g-HQ/edit?usp=sharing',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
        },
        {
            imageName: 'instruction-agreements',
            preTitle: 'Early Stage OFFICE',
            title: 'Instrukcja: Płatności i Umowy Online  ',
            url: 'https://docs.google.com/presentation/d/1igR9kqQbwi4ZWUKYt14JjcEtrM4eA1vWepWAgFKNU08/edit?usp=sharing',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT', 'ROLE_PAYMENTS'],
        },
        {
            imageName: 'instruction-dos',
            preTitle: 'Early Stage OFFICE',
            title: 'Instrukcja dla METODYKA',
            url: 'https://docs.google.com/presentation/d/1sug3-z871eQvwJl3x4x7ICQhrAnamDfGCghmLKkRva0/edit?usp=sharing',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT', 'ROLE_DOS'],
        },
        {
            imageName: 'instruction-lector',
            preTitle: 'Early Stage OFFICE',
            title: 'Instrukcja dla Lektora',
            url: 'https://docs.google.com/presentation/d/1Vhi0vMiv9S5IcBfvw7_muU42MGbEGgXi7ySwwHUU2jg/edit?usp=sharing',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_DOS', 'ROLE_PAYMENTS', 'ROLE_LECTOR', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
        },
        {
            imageName: 'instruction-office',
            preTitle: 'Asystent Filii',
            title: 'Instrukcja',
            url: 'https://docs.google.com/presentation/d/1PSUqF7fa7almwX3tCutvaU080klDnQAe4dXtsR6jkkI/',
            roles: ['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT'],
        },
    ];

    public instructions: TInstruction[] = [];

    public enableSlackLink: boolean = false;

    constructor(
        private store: Store,
        private modals: ModalsService
    ) {}

    public ngOnInit(): void {
        this.enableSlackLink = this.store.selectSnapshot(ProfileState.someRole(['ROLE_FRANCHISEE', 'ROLE_SUPER_ADMIN', 'ROLE_LEADER', 'ROLE_SECRETARIAT']));

        const roles = this.store.selectSnapshot(ProfileState.roles);

        if (roles && roles.length > 0) {
            for (const instruction of this.allInstructions) {
                const hasSomeRole = roles.some(r => instruction.roles.some(rName => r.role === rName));

                if (hasSomeRole) {
                    this.instructions.push(instruction);
                }
            }
        }
    }

    public openSettingsModal(): void {
        this.modals.open(SettingsModalComponent, {
            style: 'anchored',
            anchorType: 'right',
            width: 500,
        });
    }
}
