import { Injectable } from '@angular/core';
import { IRole, IRoleDTO } from '../models/role.interface';
import { Observable, ReplaySubject } from 'rxjs';
import { ApiClientService } from './api.service';
import { IResults } from '../models/results.interface';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RolesService {
    public rolesSubject: ReplaySubject<IRole[]>;
    public rolesSnapshot: IRole[];

    constructor(public api: ApiClientService) {}

    public getRoles(): Observable<IRole[]> {
        if (!this.rolesSubject) {
            return this.loadRoles();
        }

        return this.rolesSubject.asObservable();
    }

    public loadRoles(): Observable<IRole[]> {
        this.rolesSubject = new ReplaySubject(1);

        return this.api.get<IResults<IRoleDTO[]>>('roles').pipe(
            map(response => this.mapRoles(response.results)),
            tap(roles => {
                this.rolesSubject.next(roles);
                this.rolesSnapshot = roles;
            })
        );
    }

    public mapRoles(rolesDto: IRoleDTO[]): IRole[] {
        const roles = [] as IRole[];

        for (const roleDto of rolesDto) {
            roles.push({
                id: roleDto.id,
                role: roleDto.role,
                name: roleDto.name,
                weight: roleDto.weight,
                color: roleDto.color,
            });
        }

        return roles;
    }

    public getRole(role: string): IRole {
        const roles = this.rolesSnapshot;

        if (!roles) {
            return undefined;
        }

        return roles.find(r => r.role === role);
    }
}
