import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { IProvince } from '../../models/province.interface';
import { tap } from 'rxjs/operators';
import { ProvincesService } from '../../services/dictionary/provinces.service';
import { IRole } from '../../models/role.interface';
import { RolesService } from '../../services/roles.service';
import { of } from 'rxjs';
import {
    EditSchoolYear,
    LoadAdminFranchises,
    LoadApiVersion,
    LoadAppVersion,
    LoadGroupColors,
    LoadLevels,
    LoadPolls,
    LoadProvinces,
    LoadProvincesIfEmpty,
    LoadRelations,
    LoadRelationsIfEmpty,
    LoadRolesIfEmpty,
    LoadSchoolSettings,
    LoadSchoolSettingsIfEmpty,
    LoadSchoolTypes,
    LoadSchoolTypesIfEmpty,
    LoadSchoolYears,
    LoadSelectedYearLevels,
    LoadStreetTypes,
    ResetSelectedYearLevels,
} from './dictionary.actions';
import { RelationsService } from '../../services/dictionary/relations.service';
import { IRelation } from '../../models/relation.interface';
import { ISchoolType } from '../../models/school-type.interface';
import { SchoolTypesService } from '../../services/dictionary/school-types.service';
import { ISchoolSettings } from '../../models/school-settings.interface';
import { SchoolSettingsService } from '../../services/dictionary/school-settings.service';
import { Injectable } from '@angular/core';
import { SchoolYearsService } from '../../services/dictionary/school-years.service';
import { ISchoolYear } from '../../models/school-year.interface';
import { ILevelBase } from '../../models/level.interface';
import { LevelsService } from '../../services/levels.service';
import { IPoll, PollsService } from '../../services/polls.service';
import { ILayoutStateModel, LayoutState } from '../layout/layout.state';
import { FranchiseService } from '../../services/franchise.service';
import { IFranchiseListItem } from '../../models/franchise.interface';
import { StreetTypesService } from '../../services/dictionary/street-types.service';
import { IStreetType } from '../../models/agency.interface';
import { IAgreementConsent } from '../../models/agreement.interface';
import { AgreementConsentsService } from '../../services/dictionary/agreement-consents.service';
import { LoadAgreementConsentsIfEmpty } from 'apps/early-stage-office/src/app/modules/franchise/store/franchise-page.actions';
import { VersionService } from '../../services/dictionary/version.service';
import { GroupColorsService } from '../../services/dictionary/group-colors.service';
import { IGroupColor } from '../../models/group.interface';

export interface IDictionaryStateModel {
    schoolSettings: ISchoolSettings;
    provinces: IProvince[];
    roles: IRole[];
    relations: IRelation[];
    schoolTypes: ISchoolType[];
    schoolYears: ISchoolYear[];
    levels: ILevelBase[];
    selectedYearLevels: ILevelBase[];
    polls: IPoll[];
    franchises: IFranchiseListItem[];
    streetTypes: IStreetType[];
    groupsColors: IGroupColor[];

    agreementConsents: {
        year: number;
        consents: IAgreementConsent[];
    }[];
    apiVersion: string;
    appVersion: string;
}

@State<IDictionaryStateModel>({
    name: 'common',
    defaults: {
        provinces: null,
        roles: null,
        relations: null,
        schoolTypes: null,
        schoolSettings: null,
        schoolYears: null,
        levels: null,
        selectedYearLevels: null,
        polls: null,
        franchises: null,
        streetTypes: null,
        agreementConsents: null,
        apiVersion: null,
        appVersion: null,
        groupsColors: null,
    },
})
@Injectable()
export class DictionaryState {
    constructor(
        private provinces: ProvincesService,
        private roles: RolesService,
        private relationsService: RelationsService,
        private schoolTypesService: SchoolTypesService,
        private schoolSettingsService: SchoolSettingsService,
        private schoolYearsService: SchoolYearsService,
        private levelsService: LevelsService,
        private pollsService: PollsService,
        private franchises: FranchiseService,
        private streetTypesService: StreetTypesService,
        private agreementConsents: AgreementConsentsService,
        private version: VersionService,
        private groupColorsService: GroupColorsService
    ) {}

    @Selector()
    public static getState(state: IDictionaryStateModel) {
        return state;
    }

    @Selector()
    public static franchises(state: IDictionaryStateModel) {
        return state.franchises;
    }

    @Selector()
    public static streetTypes(state: IDictionaryStateModel) {
        return state.streetTypes;
    }

    @Selector()
    public static provinces(state: IDictionaryStateModel) {
        return state.provinces;
    }

    @Selector()
    public static schoolSettings(state: IDictionaryStateModel) {
        return state.schoolSettings;
    }

    @Selector()
    public static currentSchoolYear(state: IDictionaryStateModel) {
        return state.schoolYears.find(y => y.isCurrent);
    }

    @Selector()
    public static ESOnlineSchoolYear(state: IDictionaryStateModel) {
        return state.schoolYears.find(y => y.isCurrentOnline);
    }

    @Selector()
    public static schoolYears(state: IDictionaryStateModel) {
        return state.schoolYears;
    }

    @Selector()
    public static paymentsSchoolYears(state: IDictionaryStateModel) {
        return state.schoolYears?.filter(f => f.id >= 5);
    }

    @Selector()
    public static validSchoolYears(state: IDictionaryStateModel) {
        return state.schoolYears?.filter(f => !f.isHidden);
    }

    @Selector()
    public static exportSchoolYears(state: IDictionaryStateModel) {
        const currentDraftYear = state.schoolYears.find(y => y.isCurrentDraft);
        return state.schoolYears?.filter(f => f.id !== 1 && f.id <= currentDraftYear?.id).reverse();
    }

    @Selector()
    public static currentDraftSchoolYear(state: IDictionaryStateModel) {
        return state.schoolYears.find(y => y.isCurrentDraft);
    }

    @Selector()
    public static availableSchoolYears(state: IDictionaryStateModel) {
        const years = state.schoolYears.filter(y => y.isCurrentDraft || y.isCurrent);

        return years;
    }

    @Selector()
    public static apiVersion(state: IDictionaryStateModel) {
        return state.apiVersion;
    }
    @Selector()
    public static appVersion(state: IDictionaryStateModel) {
        return state.appVersion;
    }

    @Selector()
    public static groupColors(state: IDictionaryStateModel) {
        return state.groupsColors;
    }

    public static agreementConsents(schoolYearId: number) {
        return createSelector(
            [this],
            (state: IDictionaryStateModel) => {
                return state.agreementConsents.find(c => c.year === schoolYearId)?.consents;
            },
            { containerClass: DictionaryState, selectorName: 'agreementConsents' }
        );
    }

    public static groupedAgreementConsents(schoolYearId: number) {
        return createSelector(
            [this],
            (state: IDictionaryStateModel) => {
                const consentsDictionary = state.agreementConsents.find(c => c.year === schoolYearId)?.consents;
                if (!consentsDictionary || consentsDictionary.length === 0) {
                    return null;
                }

                const groupedList = [];

                for (const consent of consentsDictionary) {
                    if (!consent.parentConsentId) {
                        groupedList.push({
                            parent: consent,
                            allConsents: [consent],
                        });
                    }
                }

                for (const consent of consentsDictionary) {
                    if (consent.parentConsentId) {
                        const grouped = groupedList.find(c => c.parent?.id === consent.parentConsentId);

                        grouped.allConsents.push(consent);
                    }
                }

                return groupedList;
            },
            { containerClass: DictionaryState, selectorName: 'groupedAgreementConsents' }
        );
    }

    public static isCurrentYear(schoolYearId: number) {
        return createSelector([this], (state: IDictionaryStateModel) => {
            return state.schoolYears.some(c => c.id === schoolYearId && c.isCurrent);
        });
    }

    public static isDraftYear(schoolYearId: number) {
        return createSelector([this], (state: IDictionaryStateModel) => {
            return state.schoolYears.some(c => c.id === schoolYearId && c.isCurrentDraft);
        });
    }

    public static isArchivedYear(schoolYearId: number) {
        return createSelector([this], (state: IDictionaryStateModel) => {
            const current = state.schoolYears.find(y => y.isCurrent);

            return schoolYearId < current?.id;
        });
    }

    public static nextSchoolYear(schoolYearId: number) {
        return createSelector([this], (state: IDictionaryStateModel) => {
            return state.schoolYears.find(c => c.id === schoolYearId + 1);
        });
    }

    public static previousSchoolYear(schoolYearId: number) {
        return createSelector([this], (state: IDictionaryStateModel) => {
            return state.schoolYears.find(c => c.id === schoolYearId - 1);
        });
    }

    @Selector()
    public static availableAgreementsSchoolYears(state: IDictionaryStateModel) {
        const currentDraft = state.schoolYears.find(y => y.isCurrentDraft);

        return state.schoolYears.filter(y => y.id <= currentDraft.id).reverse();
    }

    @Selector()
    public static availableDeclarationsSchoolYears(state: IDictionaryStateModel) {
        const currentDraft = state.schoolYears.find(y => y.isCurrentDraft);

        return state.schoolYears.filter(y => y.id < currentDraft.id).reverse();
    }

    @Selector()
    public static archivedYears(state: IDictionaryStateModel) {
        const current = state.schoolYears.find(y => y.isCurrent);

        return state.schoolYears.filter(y => y.id < current.id).reverse();
    }

    @Selector()
    public static visibleSchoolYears(state: IDictionaryStateModel) {
        const years = state.schoolYears.filter(y => y.isCurrentDraft || y.isCurrent);

        if (years.length === 1) {
            const allYears = state.schoolYears;
            const previous = allYears.filter(y => y.dateFrom < years[0].dateFrom).sort((a, b) => (a.dateFrom > b.dateFrom ? -1 : 1))[0];

            if (previous) {
                years.unshift(previous);
            }
        }
        return years;
    }

    @Selector()
    public static studentSchoolTypes(state: IDictionaryStateModel) {
        return state.schoolTypes.filter(s => s.isStudentSetting);
    }

    @Selector()
    public static agencySchoolTypes(state: IDictionaryStateModel) {
        return state.schoolTypes.filter(s => s.isAgencySetting);
    }

    @Selector()
    public static relations(state: IDictionaryStateModel) {
        return state.relations;
    }

    @Selector()
    public static mainRelations(state: IDictionaryStateModel) {
        return state.relations.filter(r => !r.isPupil);
    }

    @Selector()
    public static schoolTypes(state: IDictionaryStateModel) {
        return state.schoolTypes;
    }

    @Selector()
    public static province(id: number) {
        return createSelector([DictionaryState], (states: { common: IDictionaryStateModel }) => {
            return states.common.provinces.find(d => d.id === id);
        });
    }

    @Selector()
    public static levels(state: IDictionaryStateModel): ILevelBase[] {
        return state.levels;
    }

    @Selector([DictionaryState, LayoutState])
    public static polls(state: IDictionaryStateModel, layout: ILayoutStateModel): IPoll[] {
        return state.polls.filter(p => !p.siteContexts || p.siteContexts.some(s => s === layout.siteContext));
    }

    public static realizations(levelId: number) {
        return createSelector([this], (state: IDictionaryStateModel) => {
            return state.levels.find(c => c.id === levelId)?.realizations;
        });
    }

    @Selector()
    public static selectedYearLevels(state: IDictionaryStateModel): ILevelBase[] {
        return state.selectedYearLevels;
    }

    public static selectedYearRealizations(levelId: number) {
        return createSelector([this], (state: IDictionaryStateModel) => {
            return state.selectedYearLevels.find(c => c.id === levelId)?.realizations;
        });
    }

    @Action(LoadProvinces)
    public loadProvinces({ patchState }: StateContext<IDictionaryStateModel>) {
        return this.provinces.getProvinces().pipe(
            tap(provinces => {
                patchState({
                    provinces,
                });
            })
        );
    }

    @Action(LoadSchoolYears)
    public loadSchoolYears({ patchState }: StateContext<IDictionaryStateModel>) {
        return this.schoolYearsService.loadSchoolYears().pipe(
            tap(schoolYears => {
                patchState({
                    schoolYears,
                });
            })
        );
    }

    @Action(EditSchoolYear)
    public editSchoolYear({ patchState, getState }: StateContext<IDictionaryStateModel>, { payload }: EditSchoolYear) {
        return this.schoolYearsService.editSchoolYear(payload.data).pipe(
            tap(schoolYears => {
                // TODO: Poprawić logikę, wziąć pod uwagę nowe i stare rekordy
                patchState({
                    schoolYears: schoolYears,
                });
            })
        );
    }

    @Action(LoadProvincesIfEmpty)
    public loadProvincesIfEmpty({ getState, dispatch }: StateContext<IDictionaryStateModel>) {
        const provinces = getState() ? getState().provinces : null;

        if (!provinces || provinces.length === 0) {
            return dispatch(new LoadProvinces());
        } else {
            return of(provinces);
        }
    }

    @Action(LoadRolesIfEmpty)
    public loadRolesIfEmpty({ getState, patchState }: StateContext<IDictionaryStateModel>) {
        const roles = getState().roles;

        if (!roles || roles.length === 0) {
            return this.roles.getRoles().pipe(
                tap(t => {
                    patchState({
                        roles: t,
                    });
                })
            );
        } else {
            return of(roles);
        }
    }

    @Action(LoadRelations)
    public loadRelations({ patchState }: StateContext<IDictionaryStateModel>) {
        return this.relationsService.getRelations().pipe(
            tap(relations => {
                patchState({
                    relations,
                });
            })
        );
    }

    @Action(LoadRelationsIfEmpty)
    public loadRelationsIfEmpty({ getState, dispatch }: StateContext<IDictionaryStateModel>) {
        const relations = getState() ? getState().relations : null;

        if (!relations || relations.length === 0) {
            return dispatch(new LoadRelations());
        }
    }

    @Action(LoadSchoolTypes)
    public loadSchoolTypes({ patchState }: StateContext<IDictionaryStateModel>) {
        return this.schoolTypesService.getSchoolTypes().pipe(
            tap(types => {
                patchState({
                    schoolTypes: types,
                });
            })
        );
    }

    @Action(LoadSchoolTypesIfEmpty)
    public loadSchoolTypesIfEmpty({ getState, dispatch }: StateContext<IDictionaryStateModel>) {
        const types = getState() ? getState().schoolTypes : null;

        if (!types || types.length === 0) {
            return dispatch(new LoadSchoolTypes());
        }
    }

    @Action(LoadSchoolSettings)
    public loadSchoolSettings({ patchState }: StateContext<IDictionaryStateModel>) {
        return this.schoolSettingsService.getSchoolSettings().pipe(
            tap(settings => {
                patchState({
                    schoolSettings: settings,
                });
            })
        );
    }

    @Action(LoadSchoolSettingsIfEmpty)
    public loadSchoolSettingsIfEmpty({ getState, dispatch }: StateContext<IDictionaryStateModel>) {
        const settings = getState() ? getState().schoolSettings : null;

        if (!settings) {
            return dispatch(new LoadSchoolSettings());
        }
    }

    @Action(LoadLevels)
    public loadFranchiseLevels({ getState, patchState }: StateContext<IDictionaryStateModel>) {
        return this.levelsService.get().pipe(tap(fs => patchState({ levels: fs })));
    }

    @Action(LoadGroupColors)
    public loadGroupColors({ getState, patchState }: StateContext<IDictionaryStateModel>) {
        return this.groupColorsService.getGroupColors().pipe(tap(colors => patchState({ groupsColors: colors })));
    }

    @Action(LoadSelectedYearLevels)
    public loadCurrentYearLevels({ getState, patchState }: StateContext<IDictionaryStateModel>, { payload }: LoadSelectedYearLevels) {
        const year = payload.schoolYearId;

        const params = {};

        if (year) {
            params['filter[schoolYear]'] = year;
        }

        return this.levelsService.get(params).pipe(tap(fs => patchState({ selectedYearLevels: fs })));
    }

    @Action(ResetSelectedYearLevels)
    public resetCurrentYearLevels({ getState, patchState }: StateContext<IDictionaryStateModel>) {
        patchState({ selectedYearLevels: null });
    }

    @Action(LoadPolls)
    public loadPolls({ getState, patchState }: StateContext<IDictionaryStateModel>) {
        return this.pollsService.get().pipe(tap(fs => patchState({ polls: fs })));
    }

    @Action(LoadAdminFranchises)
    public loadAdminFranchises({ getState, patchState }: StateContext<IDictionaryStateModel>) {
        return this.franchises.get().pipe(tap(fs => patchState({ franchises: fs })));
    }

    @Action(LoadStreetTypes)
    public loadStreetTypes({ getState, patchState }: StateContext<IDictionaryStateModel>) {
        return this.streetTypesService.getStreetTypes().pipe(tap(streetTypes => patchState({ streetTypes })));
    }

    @Action(LoadAgreementConsentsIfEmpty)
    public LoadAgreementConsentsIfEmpty({ getState, patchState }: StateContext<IDictionaryStateModel>, { payload }: LoadAgreementConsentsIfEmpty) {
        const consents = getState().agreementConsents || [];
        const yearConsents = consents?.find(c => c.year === payload.yearId);

        if (!yearConsents) {
            return this.agreementConsents.get(payload.yearId).pipe(
                tap(c => {
                    consents.push({
                        consents: c,
                        year: payload.yearId,
                    });

                    patchState({
                        agreementConsents: consents,
                    });
                })
            );
        }
    }

    @Action(LoadApiVersion)
    public loadApiVersion({ getState, patchState }: StateContext<IDictionaryStateModel>) {
        return this.version.getApiVersion().pipe(tap(apiVersion => patchState({ apiVersion })));
    }

    @Action(LoadAppVersion)
    public loadAppVersion({ getState, patchState }: StateContext<IDictionaryStateModel>) {
        return this.version.getAppVersion().pipe(tap(appVersion => patchState({ appVersion })));
    }
}
