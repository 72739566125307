import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DictionaryState } from '../../../core/store/dictionary/dictionary.state';
import { Observable } from 'rxjs';
import { LoadApiVersion, LoadAppVersion } from '../../../core/store/dictionary/dictionary.actions';
import { NgIf, AsyncPipe } from '@angular/common';
import { PrivacyConsentsModalsService } from '@early-stage/components';

@Component({
    selector: 'es-settings-modal',
    templateUrl: './settings-modal.component.html',
    styleUrls: ['./settings-modal.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe],
})
export class SettingsModalComponent implements OnInit {
    @Select(DictionaryState.apiVersion) public apiVersion$: Observable<string>;
    @Select(DictionaryState.appVersion) public appVersion$: Observable<string>;

    constructor(
        private store: Store,
        private cookiesPolicyModals: PrivacyConsentsModalsService
    ) {}

    public ngOnInit(): void {
        this.store.dispatch(new LoadApiVersion());
        this.store.dispatch(new LoadAppVersion());
    }

    public openCookiesSettingsModal(): void {
        this.cookiesPolicyModals.openConsentsSettingsModal();
    }
}
