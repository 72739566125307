import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { IResults } from '../../models/results.interface';
import { IStreetType } from '../../models/agency.interface';

@Injectable({
    providedIn: 'root',
})
export class StreetTypesService {
    private streetTypesSubject: ReplaySubject<IStreetType[]>;

    constructor(private api: ApiClientService) {}

    public getStreetTypes(): Observable<IStreetType[]> {
        if (!this.streetTypesSubject) {
            return this.loadStreetTypes();
        }

        return this.streetTypesSubject.asObservable();
    }

    public loadStreetTypes(): Observable<IStreetType[]> {
        this.streetTypesSubject = new ReplaySubject(1);

        return this.api.get<IResults<IStreetType[]>>('dictionary/streetTypes').pipe(
            map(r => r.results),
            tap(r => {
                this.streetTypesSubject.next(r);
            })
        );
    }
}
