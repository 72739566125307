<div>
    <div class="modal-overlay" [@fadeAnimation] *ngIf="!wasClosed" [ngClass]="{ 'is-online': style === 'online', 'is-anchored': style === 'anchored' }"></div>

    <ng-container *ngIf="style === 'normal'">
        <div *ngIf="isMobile">
            <div #modalWrapper class="modal-wrapper" *ngIf="!wasClosed" [@fromBottomAnimation] data-scroll-lock-scrollable>
                <ng-container *ngTemplateOutlet="modalcontent"></ng-container>
            </div>
        </div>

        <div *ngIf="!isMobile">
            <div #modalWrapper class="modal-wrapper" *ngIf="!wasClosed" [@fadeAnimation] data-scroll-lock-scrollable>
                <ng-container *ngTemplateOutlet="modalcontent"></ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="style === 'anchored'">
        <div *ngIf="isMobile">
            <div #modalWrapper class="modal-wrapper is-anchored is-anchored-{{ anchorType }}" *ngIf="!wasClosed" [@fromBottomAnimation] data-scroll-lock-scrollable>
                <ng-container *ngTemplateOutlet="modalcontent"></ng-container>
            </div>
        </div>

        <div *ngIf="!isMobile">
            <ng-container *ngIf="anchorType === 'left'">
                <div #modalWrapper class="modal-wrapper is-anchored is-anchored-left" *ngIf="!wasClosed" [@fromLeftAnimation] data-scroll-lock-scrollable>
                    <ng-container *ngTemplateOutlet="modalcontent"></ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="anchorType === 'bottom'">
                <div #modalWrapper class="modal-wrapper is-anchored is-anchored-bottom" *ngIf="!wasClosed" [@fromBottomAnimation] data-scroll-lock-scrollable>
                    <ng-container *ngTemplateOutlet="modalcontent"></ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="anchorType === 'right'">
                <div #modalWrapper class="modal-wrapper is-anchored is-anchored-right" *ngIf="!wasClosed" [@fromRightAnimation] data-scroll-lock-scrollable>
                    <ng-container *ngTemplateOutlet="modalcontent"></ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="style === 'online'">
        <div #modalWrapper class="modal-wrapper is-online" *ngIf="!wasClosed" [@fadeAnimation]>
            <ng-container *ngTemplateOutlet="modalcontent"></ng-container>
        </div>
    </ng-container>
</div>

<ng-template #modalcontent>
    <div class="modal-wrapper__content">
        <div
            class="modal"
            #modal
            data-scroll-lock-scrollable
            [ngClass]="{
                'is-online': style === 'online'
            }"
            [ngStyle]="{ 'width.px': width }">
            <button class="modal__close" (click)="closeClick()" *ngIf="modalCloseButton">
                <div class="icon icon-close" [attr.aria-label]="'close'" [ngStyle]="{ 'width.px': 14, 'height.px': 14 }" [inlineSVG]="'assets/icons/close.svg'"></div>
            </button>

            <div #modalContainer class="modal__container">
                <ng-template esModalTemplate></ng-template>
            </div>
        </div>
    </div>
</ng-template>
