import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalConfig } from './modal-config';
import { Inject, Injectable } from '@angular/core';

/**
 * @deprecated Use new EscModal system
 */
@Injectable()
export class ModalRef {
    private readonly _afterClosing = new Subject<any>();
    private readonly _configSubject = new Subject<ModalConfig>();
    public afterClosing$: Observable<any> = this._afterClosing.asObservable();
    public config$: Observable<any> = this._configSubject.asObservable();

    constructor(@Inject(ModalConfig) private config?: ModalConfig) {}

    public close(result?: any) {
        this._afterClosing.next(result);
    }

    public result(): Promise<any> {
        return this.afterClosing$.pipe(take(1)).toPromise();
    }

    public changeWidth(width: number): void {
        if (this.config) {
            this.config.width = width;
            this._configSubject.next(this.config);
        }
    }
}
