import { Injectable, ComponentFactoryResolver, ComponentRef, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { SnackbarContainerComponent } from '../components/snackbar-container/snackbar-container.component';
import { SnackbarConfig } from './snackbar-config';
import { EscToastsService } from '@early-stage/components';

/**
 * @deprecated Use `EscToastsService`
 */
@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    private snackbarComponentRef: ComponentRef<SnackbarContainerComponent>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private _toasts: EscToastsService
    ) {}

    public add(config: SnackbarConfig): void {
        this._toasts.add({
            type: config.type || 'success',
            text: config.text,
            timeout: 4000,
        });
    }
}
