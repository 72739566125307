import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { IResults } from '../../models/results.interface';
import { IGroupColor } from '../../models/group.interface';

@Injectable({
    providedIn: 'root',
})
export class GroupColorsService {
    constructor(private api: ApiClientService) {}

    public getGroupColors(): Observable<IGroupColor[]> {
        const params = {
            'filter[type]': 'group',
        };

        return this.api.get<IResults<IGroupColor[]>>('dictionary/colors', { params }).pipe(
            map(r => {
                return r.results.sort((a, b) => a.hierarchyNumber - b.hierarchyNumber);
            })
        );
    }
}
