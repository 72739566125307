import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
    AddCurrentScrollableElement,
    AddNavigationData,
    CloseMenu,
    MenuExpand,
    RemoveCurrentScrollableElement,
    RemoveNavigationItems,
    ResetLayoutState,
    ResetNavigationItems,
    SaveReturnUrl,
    SaveReturnUrlAfterLogin,
    SetCurrentContext,
    SetCurrentContextFranchise,
    SetCurrentlyEditedForm,
    SetIsMobile,
    SetIsPageWithFooter,
    SetPreviousUrl,
    SetTableSnapshot,
    SetTableSnapshotFilterQuery,
    SetTabsSnapshot,
    ToggleMenuExpand,
} from './layout.actions';
import { INavigationItem } from '../../models/navigation.interface';
import { UntypedFormGroup } from '@angular/forms';
import { enablePageScroll } from 'scroll-lock';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ITableSnapshot } from '../../models/table-snapshot.interface';
import { ITabsSnapshot } from '../../models/tabs-snapshot.interface';
import { ISingleFranchise } from '../../models/franchise.interface';

export enum SiteContext {
    Superadmin = 1,
    Franchise = 2,
    Lector = 3,
    DOS = 4,
    ClassRegister = 5,
    Profile = 6,
    Payments = 7,
}

export interface ILayoutStateModel {
    isMobile: boolean;
    isMenuExpanded: boolean;
    isPageWithFooter: boolean;
    returnUrl: string;
    returnUrlAfterLogin: string;
    navigationData: { [key: string]: string };
    ignoredNavigationItems: Partial<INavigationItem>[];
    currentlyEditedForm: UntypedFormGroup;
    currentScrollableElements: Array<HTMLElement | Document | Window>;
    previousUrl: string;
    siteContext: SiteContext;
    siteContextFranchise: ISingleFranchise;
    tableSnapshots: { [key: string]: ITableSnapshot };
    tabsSnapshots: { [key: string]: ITabsSnapshot };
}

@State<ILayoutStateModel>({
    name: 'layout',
    defaults: {
        isMobile: false,
        isPageWithFooter: false,
        isMenuExpanded: false,
        navigationData: null,
        ignoredNavigationItems: null,
        returnUrl: null,
        currentlyEditedForm: null,
        returnUrlAfterLogin: null,
        currentScrollableElements: null,
        previousUrl: null,
        siteContext: null,
        siteContextFranchise: null,
        tableSnapshots: {},
        tabsSnapshots: {},
    },
})
@Injectable()
export class LayoutState {
    constructor(private router: Router) {}

    @Selector()
    public static getState(state: ILayoutStateModel) {
        return state;
    }

    public static tableSnapshot(id: string) {
        return createSelector([this], (state: ILayoutStateModel) => {
            return state.tableSnapshots[id];
        });
    }

    public static tabsSnapshot(id: string) {
        return createSelector([this], (state: ILayoutStateModel) => {
            return state.tabsSnapshots[id];
        });
    }

    @Selector()
    public static ignoredNavigationItems(state: ILayoutStateModel) {
        return state.ignoredNavigationItems;
    }

    @Selector()
    public static navigationData(state: ILayoutStateModel) {
        return state.navigationData;
    }

    @Selector()
    public static currentScrollableElements(state: ILayoutStateModel) {
        return state.currentScrollableElements;
    }

    @Selector()
    public static currentScrollableElement(state: ILayoutStateModel) {
        return state.currentScrollableElements.length > 0 ? state.currentScrollableElements[state.currentScrollableElements.length - 1] : null;
    }

    @Selector()
    public static currentlyEditedForm(state: ILayoutStateModel) {
        return state.currentlyEditedForm;
    }

    @Selector()
    public static isMobile(state: ILayoutStateModel): boolean {
        return state.isMobile;
    }
    @Selector()
    public static isDesktop(state: ILayoutStateModel): boolean {
        return !state.isMobile;
    }

    @Selector()
    public static siteContext(state: ILayoutStateModel): SiteContext {
        return state.siteContext;
    }

    @Selector()
    public static siteContextFranchise(state: ILayoutStateModel): ISingleFranchise {
        return state.siteContextFranchise;
    }

    @Selector()
    public static isPageWithFooter(state: ILayoutStateModel): boolean {
        return state.isPageWithFooter;
    }

    @Selector()
    public static menuExpand(state: ILayoutStateModel) {
        return state.isMenuExpanded;
    }

    @Selector()
    public static returnUrl(state: ILayoutStateModel) {
        return state.returnUrl;
    }

    @Selector()
    public static returnUrlAfterLogin(state: ILayoutStateModel) {
        return state.returnUrlAfterLogin;
    }

    @Selector()
    public static previousUrl(state: ILayoutStateModel) {
        return state.previousUrl;
    }

    @Action(MenuExpand)
    public menuExpand({ patchState, getState }: StateContext<ILayoutStateModel>, { payload }: MenuExpand) {
        const isExpand = getState() && getState().isMenuExpanded;
        if (isExpand !== payload.isExpand) {
            patchState({ isMenuExpanded: payload.isExpand });
        }
    }

    @Action(ToggleMenuExpand)
    public toggleMenuExpand(ctx: StateContext<ILayoutStateModel>) {
        const isExpand = ctx.getState() && ctx.getState().isMenuExpanded;
        return ctx.patchState({ isMenuExpanded: !isExpand });
    }

    @Action(CloseMenu)
    public closeMenu(ctx: StateContext<ILayoutStateModel>) {
        enablePageScroll();

        return ctx.patchState({
            isMenuExpanded: false,
        });
    }

    @Action(ResetNavigationItems)
    public resetNavigationItems(ctx: StateContext<ILayoutStateModel>) {
        ctx.patchState({
            ignoredNavigationItems: [],
        });
    }

    @Action(RemoveNavigationItems)
    public removeNavigationItem(ctx: StateContext<ILayoutStateModel>, { payload }: RemoveNavigationItems) {
        const items = [...(ctx.getState().ignoredNavigationItems || [])];
        items.push(...payload.items);

        ctx.patchState({
            ignoredNavigationItems: items,
        });
    }

    @Action(AddNavigationData)
    public addNavigationData(ctx: StateContext<ILayoutStateModel>, { payload }: AddNavigationData) {
        const data = ctx.getState().navigationData;
        const newData = {};

        if (data) {
            Object.assign(newData, data);
        }

        if (payload) {
            Object.assign(newData, payload.data);
        }

        ctx.patchState({
            navigationData: newData,
        });
    }

    @Action(SaveReturnUrl)
    public saveReturnUrl(ctx: StateContext<ILayoutStateModel>, { payload }: SaveReturnUrl) {
        const returnUrl = payload ? payload.url : this.router.url;
        ctx.patchState({
            returnUrl,
        });
    }

    @Action(SetCurrentContext)
    public SetCurrentContext(ctx: StateContext<ILayoutStateModel>, { payload }: SetCurrentContext) {
        ctx.patchState({
            siteContext: payload.context,
        });
    }

    @Action(SetCurrentContextFranchise)
    public setCurrentContextFranchise(ctx: StateContext<ILayoutStateModel>, { payload }: SetCurrentContextFranchise) {
        ctx.patchState({
            siteContextFranchise: payload.context,
        });
    }

    @Action(SaveReturnUrlAfterLogin)
    public saveReturnUrlAfterLogin(ctx: StateContext<ILayoutStateModel>, { payload }: SaveReturnUrlAfterLogin) {
        const returnUrlAfterLogin = payload ? payload.url : this.router.url;
        ctx.patchState({
            returnUrlAfterLogin,
        });
    }

    @Action(SetCurrentlyEditedForm)
    public setCurrentlyEditedForm(ctx: StateContext<ILayoutStateModel>, { payload }: SetCurrentlyEditedForm) {
        ctx.patchState({
            currentlyEditedForm: payload.form,
        });
    }

    @Action(SetIsMobile)
    public setIsMobile(ctx: StateContext<ILayoutStateModel>, { payload }: SetIsMobile) {
        ctx.patchState({
            isMobile: payload.isMobile,
        });
    }

    @Action(SetIsPageWithFooter)
    public setIsPageWithFooter(ctx: StateContext<ILayoutStateModel>, { payload }: SetIsPageWithFooter) {
        ctx.patchState({
            isPageWithFooter: payload.isPageWithFooter,
        });
    }

    @Action(AddCurrentScrollableElement)
    public addCurrentScrollableElement(ctx: StateContext<ILayoutStateModel>, { payload }: AddCurrentScrollableElement) {
        const elements = ctx.getState().currentScrollableElements || [];
        elements.push(payload.element);

        ctx.patchState({
            currentScrollableElements: elements,
        });
    }

    @Action(RemoveCurrentScrollableElement)
    public removeCurrentScrollableElement(ctx: StateContext<ILayoutStateModel>, { payload }: RemoveCurrentScrollableElement) {
        let elements = ctx.getState().currentScrollableElements || [];
        elements = elements.filter(e => e !== payload.element);

        ctx.patchState({
            currentScrollableElements: elements,
        });
    }

    @Action(SetPreviousUrl)
    public setPreviousUrl(ctx: StateContext<ILayoutStateModel>, { payload }: SetPreviousUrl) {
        ctx.patchState({
            previousUrl: payload.url,
        });
    }

    @Action(SetTableSnapshot)
    public setDataSourceQueryData({ patchState, getState }: StateContext<ILayoutStateModel>, { payload }: SetTableSnapshot) {
        const data = getState().tableSnapshots;
        data[payload.name] = payload.data;

        patchState({
            tableSnapshots: data,
        });
    }

    @Action(SetTabsSnapshot)
    public setTabsSnapshot({ patchState, getState }: StateContext<ILayoutStateModel>, { payload }: SetTabsSnapshot) {
        const data = getState().tabsSnapshots;
        data[payload.name] = payload.data;

        patchState({
            tabsSnapshots: data,
        });
    }

    @Action(SetTableSnapshotFilterQuery)
    public setTableSnapshotFilterQuery({ patchState, getState }: StateContext<ILayoutStateModel>, { payload }: SetTableSnapshotFilterQuery) {
        const data = getState().tableSnapshots;
        data[payload.name] = data[payload.name] || {
            dataSourceSettings: {
                searchQuery: '',
                sortType: '',
            },
        };

        if (data[payload.name] && data[payload.name].dataSourceSettings) {
            data[payload.name].dataSourceSettings.searchQuery = payload.searchQuery;
        }

        patchState({
            tableSnapshots: data,
        });
    }

    @Action(ResetLayoutState)
    public resetLayoutState({ patchState }: StateContext<ILayoutStateModel>) {
        patchState({
            // isMobile: false,
            isPageWithFooter: false,
            isMenuExpanded: false,
            navigationData: null,
            ignoredNavigationItems: null,
            returnUrl: null,
            currentlyEditedForm: null,
            // returnUrlAfterLogin: null,
            // currentScrollableElements: null,
            previousUrl: null,
            siteContext: null,
            tableSnapshots: {},
            tabsSnapshots: {},
        });
    }
}
